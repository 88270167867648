<template>
  <div>
    <div class="bg-white px-1 rounded-lg">
      <h2 class="font-weight-bolder font-medium-5 pt-2">
        {{ $t('Transfer Items') }}
      </h2>
      <span>
        <feather-icon
          icon="LWarningIconBlue"
          class="featherIcon"
          size="30"
        />
        {{ $t('To start the pick process, press pick or uncheck the items not to be picked.') }}</span>
      <h2 class="font-weight-bolder font-medium-5 pt-2">
        {{ $t('Order RO-') }}1024
      </h2>
      <div>
        <l-table-list-collector
          :ref="`lTableRef-${1}`"
          :table-columns="tableColumns"
          :module-name="MODULE_NAME"
          :is-searchable.camel="false"
          :has-footer="false"
          :fetched-data="transferOrders.order_items"
          :is-fullfillment="true"
        >
          <template #head(action)>
            <div
              class="d-flex"
              style="padding-left: 16px"
            >
              <b-form-checkbox class="_custom_checkbox" />
            </div>
          </template>

          <template
            #cell(action)="{data: {item}}"
          >
            <div
              class="d-flex"
            >
              <b-form-checkbox
                class="_custom_checkbox"
              />
            </div>
          </template>
          <template #cell(state)>
            <div
              class="text-center"
            >
              <feather-icon
                icon="LUpPickIcon"
                class="featherIcon"
                size="30"
              />
            </div>
          </template>
          <template #cell(sku)="{data: {item}}">
            <div
              class="text-center"
            >
              {{ item.product.sku }}
            </div>
          </template>
          <template #cell(name)="{data: {item}}">
            <div
              class="text-center"
            >
              <div class="d-flex nameCell">
                <span
                  v-if="item.icon"
                  class="badge badge-secondary border-0"
                >C</span>
                <span>{{ item.product.name }}</span>
              </div>
            </div>
          </template>
          <template #cell(location)="{data: {item}}">
            <div
              class="text-center"
            >
              {{ item.inventory_item.warehouse_location.reference_name }}
            </div>
          </template>
          <template #cell(asset_id)="{data: {item}}">
            <div
              class="text-center d-flex"
            >
              <feather-icon
                icon="LDangerIcon"
                class="lightIcon border-dotted featherIcon"
                size="30"
              />
              {{ item.asset_id }}
            </div>
          </template>
          <template #cell(picked)="{data: {item}}">
            <div class="text-center">
              <feather-icon
                v-if="item.pick_at"
                icon="LCheckIcon"
                class="lightIcon featherIcon"
                style="color: green"
                size="30"
                @click="handleClick(item)"
              />
            </div>
          </template>
          <template #cell(inspected)="{data: {item}}">
            <div class="text-center">
              <b-form-checkbox
                :checked="item.inventory_item.inspected"
                class="customCheckbox"
              />
            </div>
          </template>
        </l-table-list-collector>
      </div>

      <scan @getValue="handleScannedValue" />

      <h2 class="font-weight-bolder font-medium-5 pt-2">
        {{ $t('Permanent Transfer') }}
      </h2>
      <l-table-list-collector
        ref="lTableRef"
        :table-columns="tableColumns"
        :module-name="MODULE_NAME"
        :is-searchable.camel="false"
        :fetched-data="permanentItems"
      >
        <template #head(action)>
          <div
            class="d-flex"
            style="padding-left: 16px"
          >
            <b-form-checkbox class="_custom_checkbox" />
          </div>
        </template>
        <template #cell(action)="{data: {item}}">
          <div
            class="d-flex"
          >
            <b-form-checkbox
              v-model="picket"
              class="_custom_checkbox"
              :value="item.id"
            />
            <!--  v-model="picket" -->
            <!--  :value="item.id" -->
          </div>
        </template>
        <template #cell(state)>
          <div
            class="text-center"
          >
            <feather-icon
              icon="LUpPickIcon"
              class="featherIcon"
              size="30"
            />
          </div>
        </template>
        <template #cell(sku)="{data: {item}}">
          <div
            class="text-center"
          >
            {{ item.sku }}
          </div>
        </template>
        <template #cell(name)="{data: {item}}">
          <div
            class="text-center"
          >
            {{ item.name }}
          </div>
        </template>
        <template #cell(location)="{data: {item}}">
          <div
            class="text-center"
          >
            {{ item.location }}
          </div>
        </template>
        <template #cell(asset_id)>
          <div
            class="text-center"
          >
            <feather-icon
              icon="LDangerIcon"
              class="lightIcon border-dotted featherIcon"
              size="30"
            />
            —
          </div>
        </template>
        <template #cell(picked)>
          <div class="text-center" />
        </template>
        <template #cell(inspected)="{data: {item}}">
          <div class="text-center">
            <b-form-checkbox
              :checked="item.inspected"
              class="customCheckbox"
            />
          </div>
        </template>
      </l-table-list-collector>
    </div>
  </div>
</template>

<script>
import config from '@/views/main/warehouse/view/pick/transfer/transferConfig'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import { BFormCheckbox } from 'bootstrap-vue'
import OrderTable from '@/views/main/warehouse/view/pick/transfer/components/OrderTable.vue'
import { permanentItems } from '@/views/main/warehouse/view/pick/transfer/mockData'
import Scan from '@/views/components/Scan/Scan.vue'

export default {
  name: 'TransferItems',
  components: {
    Scan,
    OrderTable,
    LTableListCollector,
    BFormCheckbox,
  },
  data() {
    return {
      scannedAssets: '',
      id: this.$route.params.id,
    }
  },
  computed: {
    pickedElements() {
      return this.$store.state[this.MODULE_NAME].pickedElements
    },
    transferOrders() {
      return (this.$store.state[this.MODULE_NAME].transferOrder)
    },
    // transferOrderAssets() {
    //
    //   return this.$store.state[this.MODULE_NAME].transferOrder.product.assets
    // },
    // picket: {
    //   get(e) {
    //     console.log(e)
    //   },
    //
    //   set(e) {
    //     console.log(e)
    //     // return true;
    //   },
  },
  methods: {
    handleClick(item) {
      item.pick_at = null
      this.$store.commit(`${this.MODULE_NAME}/REMOVE_PICKED_ITEM`, item.id)
    },
    handleScannedValue(value) {
      Object.values(this.transferOrders.order_items).forEach(item => {
        if (item.inventory_item.serial_number === value) {
          item.pick_at = new Date().toISOString()
          this.$store.commit(`${this.MODULE_NAME}/ADD_PICKED_ITEM`, item.id)
        }
      })
    },
  },
  setup() {
    const { MODULE_NAME, tableColumns } = config()
    return {
      MODULE_NAME,
      tableColumns,
      permanentItems,
    }
  },
}
</script>

<style scoped>
.custom-checkbox .custom-control-label::before, .custom-radio .custom-control-label::after{
  width: 190px;
 }

.table-striped{
  display: none;
}

.customCheckbox {
  padding-right: 3rem !important;
  & > label {
    position: relative;
  }
}
.nameCell{
  padding-left: 1.5rem;
  gap: 10px;
  align-items: center;
}

</style>
