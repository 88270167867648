var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bg-white px-1 rounded-lg"},[_c('h2',{staticClass:"font-weight-bolder font-medium-5 pt-2"},[_vm._v(" "+_vm._s(_vm.$t('Transfer Items'))+" ")]),_c('span',[_c('feather-icon',{staticClass:"featherIcon",attrs:{"icon":"LWarningIconBlue","size":"30"}}),_vm._v(" "+_vm._s(_vm.$t('To start the pick process, press pick or uncheck the items not to be picked.')))],1),_c('h2',{staticClass:"font-weight-bolder font-medium-5 pt-2"},[_vm._v(" "+_vm._s(_vm.$t('Order RO-'))+"1024 ")]),_c('div',[_c('l-table-list-collector',{ref:("lTableRef-" + (1)),attrs:{"table-columns":_vm.tableColumns,"module-name":_vm.MODULE_NAME,"isSearchable":false,"has-footer":false,"fetched-data":_vm.transferOrders.order_items,"is-fullfillment":true},scopedSlots:_vm._u([{key:"head(action)",fn:function(){return [_c('div',{staticClass:"d-flex",staticStyle:{"padding-left":"16px"}},[_c('b-form-checkbox',{staticClass:"_custom_checkbox"})],1)]},proxy:true},{key:"cell(action)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{staticClass:"_custom_checkbox"})],1)]}},{key:"cell(state)",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('feather-icon',{staticClass:"featherIcon",attrs:{"icon":"LUpPickIcon","size":"30"}})],1)]},proxy:true},{key:"cell(sku)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.product.sku)+" ")])]}},{key:"cell(name)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex nameCell"},[(item.icon)?_c('span',{staticClass:"badge badge-secondary border-0"},[_vm._v("C")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.product.name))])])])]}},{key:"cell(location)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.inventory_item.warehouse_location.reference_name)+" ")])]}},{key:"cell(asset_id)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"text-center d-flex"},[_c('feather-icon',{staticClass:"lightIcon border-dotted featherIcon",attrs:{"icon":"LDangerIcon","size":"30"}}),_vm._v(" "+_vm._s(item.asset_id)+" ")],1)]}},{key:"cell(picked)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[(item.pick_at)?_c('feather-icon',{staticClass:"lightIcon featherIcon",staticStyle:{"color":"green"},attrs:{"icon":"LCheckIcon","size":"30"},on:{"click":function($event){return _vm.handleClick(item)}}}):_vm._e()],1)]}},{key:"cell(inspected)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_c('b-form-checkbox',{staticClass:"customCheckbox",attrs:{"checked":item.inventory_item.inspected}})],1)]}}])})],1),_c('scan',{on:{"getValue":_vm.handleScannedValue}}),_c('h2',{staticClass:"font-weight-bolder font-medium-5 pt-2"},[_vm._v(" "+_vm._s(_vm.$t('Permanent Transfer'))+" ")]),_c('l-table-list-collector',{ref:"lTableRef",attrs:{"table-columns":_vm.tableColumns,"module-name":_vm.MODULE_NAME,"isSearchable":false,"fetched-data":_vm.permanentItems},scopedSlots:_vm._u([{key:"head(action)",fn:function(){return [_c('div',{staticClass:"d-flex",staticStyle:{"padding-left":"16px"}},[_c('b-form-checkbox',{staticClass:"_custom_checkbox"})],1)]},proxy:true},{key:"cell(action)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{staticClass:"_custom_checkbox",attrs:{"value":item.id},model:{value:(_vm.picket),callback:function ($$v) {_vm.picket=$$v},expression:"picket"}})],1)]}},{key:"cell(state)",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('feather-icon',{staticClass:"featherIcon",attrs:{"icon":"LUpPickIcon","size":"30"}})],1)]},proxy:true},{key:"cell(sku)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.sku)+" ")])]}},{key:"cell(name)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"cell(location)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.location)+" ")])]}},{key:"cell(asset_id)",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('feather-icon',{staticClass:"lightIcon border-dotted featherIcon",attrs:{"icon":"LDangerIcon","size":"30"}}),_vm._v(" — ")],1)]},proxy:true},{key:"cell(picked)",fn:function(){return [_c('div',{staticClass:"text-center"})]},proxy:true},{key:"cell(inspected)",fn:function(ref){
var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_c('b-form-checkbox',{staticClass:"customCheckbox",attrs:{"checked":item.inspected}})],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }