<template>
  <div class="l-tableList-collector">
    <!-- Unified table header -->
    <div class="table-header">
      <b-table-simple
        class="header-table"
      >
        <thead>
          <tr>
            <th
              v-for="(column, index) in tableColumns"
              :key="index"
              :style="column.thStyle"
              class="header-cell"
            >
              <b-form-checkbox
                v-if="!column.label"
                v-model="selectAll"
                @input="handleSelectAll"
              />
              {{ column.label }}
            </th>

          </tr>
        </thead>
      </b-table-simple>
    </div>

    <!-- Loop through each grouped item -->
    <div
      v-for="(group, groupIndex) in tableData"
      :key="groupIndex"
    >

      <!-- Render other items in the group -->
      <b-table-simple>
        <tbody class="table">
          <tr
            v-for="(item, index) in group.rows"
            :key="index"
            class="tableRows"
          >
            <td style="width: 5%">
              <b-form-checkbox
                v-if="item.select.checkbox"
                v-model="item.select.checked"
                class="first_item"
                @input="updateSelectAll"
              />
              <div
                v-else
                class="first_item"
              />
            </td>
            <td
              :class="!item.select.checkbox ? 'stateIcon' : ''"
              style="width: 5%"
            >
              <feather-icon
                v-if="item.state === 'active'"
                icon="LUpPickIcon"
                class="lightIcon featherIcon"
                size="30"
              />
            </td>
            <td :style="{width: '10%', textAlign: 'center' }">
              {{ item.sku }}
            </td>

            <td
              style="width: 25%;"
              class="nameTd"
            >
              <div class="d-flex  nameCell">
                <span
                  v-if="item.icon"
                  class="badge badge-secondary border-0"
                >C</span>
                <span>{{ item.name }}</span>
              </div>

            </td>
            <td style="width: 15%; text-align: center">
              {{ item.location }}
            </td>

            <td style="width: 10%; text-align: center">
              <div class="assetSection">
                <feather-icon
                  icon="LDangerIcon"
                  class="lightIcon border-dotted featherIcon"
                  size="30"
                />
                <span>{{ item.asset_id || '&mdash;' }}</span>

              </div>
            </td>

            <td style="width: 10%; text-align: center">
              <b-form-checkbox
                v-model="item.picked"
                class="customCheckbox"
              />
            </td>
            <td :style="{ width: '25%', textAlign: 'center' }">
              <b-form-checkbox v-model="item.inspected"
              class="customCheckbox"
              />
            </td>
          </tr>
        </tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import { BTable, BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'OrderTable',
  components: {
    BTable,
    BFormCheckbox,
  },
  props: {
    tableColumns: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectAll: false,
    }
  },

  methods: {
    handleSelectAll() {
      // Based on selectAll, check or uncheck all individual checkboxes
      this.tableData.forEach(group => {
        group.rows.forEach(item => {
          if (this.selectAll === true) {
            item.select.checked = true
          } else {
            item.select.checked = false
          }
        })
      })
    },
    updateSelectAll() {
      const allItemsChecked = this.tableData.some(group => group.rows.some(item => item.select.checkbox && item.select.checked === true))

      const allGroupsChecked = this.tableData.every(group => group.rows.every(item => !item.select.checkbox || item.select.checked === true))

      this.selectAll = allGroupsChecked && allItemsChecked
    },
  },

}
</script>

<style scoped>
/* Table row styling */

b-form-checkbox {
  display: inline-block;
  margin: 0; /* Adjust as necessary */
}
td{
  border: none;
  padding: 0.72rem !important;
}
.table{
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  border-bottom: none;
}

.header-table{
  display: block;
  width: 100%;
}

.customCheckbox {
    & > label {
        position: relative;

    }
}


.tableRows{
  border-bottom: 1px solid #e0e0e0;
}

.tableRows:nth-child(even){
  background: rgba(251, 251, 251, 1);
}
.tableRows:last-child{
  border-radius: 0 0 8px 8px;
}

.nameCell{
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2rem;
}
.assetSection{
  display: flex;
  justify-content: center;
  gap: 4px;
}

.header-cell{
  padding: 10px 0.8rem;
}

.table-header {
  display: -webkit-box;
  align-items: baseline;
  justify-content: space-between;
  font-weight: 600;
}

.pl-1 {
  padding-left: 0.25rem;
}

.p-0 {
  padding: 0;
}

.grouped-table {
  margin-bottom: 2rem;
}

.order-header {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

/* Custom Checkbox Style */

/* Custom Badge for "C" */
.c-badge {
  background-color: #444;
  color: white;
  padding: 2px 6px;
  margin-left: 8px;
  border-radius: 4px;
  font-size: 12px;
}

/* Asset ID Icon Styling */
.asset-icon {
  margin-right: 5px;
}
</style>
