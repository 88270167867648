<template>
  <div>
    <div class="bg-white px-1 rounded-lg">
      <h2 class="font-weight-bolder font-medium-5 pt-2">
        {{ $t('Dispatch') }}
      </h2>
      <div>
        <b-row>
          <b-col md="4">
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
            >
              <span class="w-45 font-weight-bolder">
                {{ $t('Date') }}
              </span>
              <span class="w-55 font-weight-bolder">
                August 9, 2022, 2:00 PM
              </span>
            </b-col>
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
            >
              <span class="w-50 font-weight-bolder ">
                {{ $t('Method') }}
              </span>
              <span class="w-50 ">
                {{ $t('Warehouse Pick up') }}
              </span>
            </b-col>
            <b-col
              class="d-flex align-items-center justify-content-between mb-1"
            >
              <span class="w-50 font-weight-bolder">
                {{ $t('Dispatch Location') }}
              </span>
              <span class="w-50 font-weight-bolder">
                {{ $t(warehouse.label) }} {{ $t('Warehouse') }}
              </span>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/views/main/warehouse/view/pick/transfer/transferConfig'
import { BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'TransferDispatch',
  components: { BRow, BCol },
  computed: {
    transferOrder() {
      return this.$store.state[this.MODULE_NAME]
    },
    warehouse() {
      return this.$store.state.app.warehouse
    },
  },
  setup() {
    const { MODULE_NAME } = config()


    return {
      MODULE_NAME,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
