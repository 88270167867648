export const groupedItems = [
  {
    rows: [
      {
        select: { checkbox: true, checked: false }, // Indicates a selectable checkbox with checked select
        sku: 'FF0001',
        state: 'active',
        name: 'FUJIFILM X-H2S Mirrorless',
        location: 'F1-A3-R2-SA',
        assetID: '',
        picked: false,
        inspected: false,
        icon: false,
      },
      {
        select: { checkbox: false }, // No checkbox for this row
        sku: 'SKU0120',
        state: 'active',
        name: 'FujiFilm Camera Battery',
        location: 'F1-A3-R2-SC',
        assetID: '',
        picked: false,
        inspected: false,
        icon: true,
      },
      {
        select: { checkbox: false, checked: false }, // Indicates a selectable checkbox with checked select
        sku: 'FF0001',
        state: 'active',
        name: 'FUJIFILM X-H2S Mirrorless',
        location: 'F1-A3-R2-SA',
        assetID: '',
        picked: false,
        inspected: false,
        icon: true,
      },
      {
        select: { checkbox: false }, // No checkbox for this row
        sku: 'CB8234',
        state: 'active',
        name: 'FujiFilm Camera Battery',
        location: 'F1-A3-R2-SC',
        assetID: '',
        picked: false,
        inspected: false,
        icon: true,
      },
      // Additional rows...
    ],
  },
  {
    rows: [
      {
        select: { checkbox: true, checked: false }, // Selectable checkbox, checked by default
        sku: 'SA0001',
        state: 'active',
        name: 'Sony a7S III Mirrorless',
        location: 'F1-A3-R2-SB',
        assetID: '',
        picked: false,
        inspected: false,
        icon: false,
      },
      {
        select: { checkbox: false }, // No checkbox for this row
        sku: 'CB9871',
        state: 'active',
        name: 'Sony Camera Battery',
        location: 'F1-A3-R2-SC',
        assetID: '',
        picked: false,
        inspected: false,
        icon: true,
      },
    ],
  },
]

export const permanentItems = [
  {
    id: 1,
    sku: 'SKU001',
    name: 'Item 1',
    location: 'A1',
    inspected: false,
  },
  {
    id: 2,
    sku: 'SKU002',
    name: 'Item 2',
    location: 'B1',
    inspected: true,
  },
  {
    id: 3,
    sku: 'SKU003',
    name: 'Item 3',
    location: 'C1',
    inspected: false,
  },
]
