<template>
  <div>
    <skeleton v-if="loading" />
    <transfer-form
      v-else
      ref="transfer-form"
    />
  </div>
</template>

<script>

import config from '@/views/main/warehouse/view/pick/transfer/transferConfig'
import Skeleton from '@/views/main/warehouse/view/pick/transfer/components/Skeleton.vue'
import TransferForm from '@/views/main/warehouse/view/pick/transfer/components/TransferForm.vue'
import store from '@/store'
import transferModule from '@/store/main/warehouse/pick/transfer'
import { onUnmounted } from '@vue/composition-api'
import { groupedItems } from '@/views/main/warehouse/view/pick/transfer/mockData'

export default {
  name: 'Transfer',
  components: { TransferForm, Skeleton },
  data() {
    return {
      loading: false,
      id: this.$route.params.id,
    }
  },
  computed: {
    transferOrder() {
      return this.$store.state[this.MODULE_NAME]
    },
  },
  created() {
    this.$emit('updateMenu', 'warehouse-transfer-pick')
    this.refetch()
  },
  methods: {
    refetch() {
      this.loading = true
      this.$store.dispatch(`${this.MODULE_NAME}/getTransferOrders`, this.$route.params.id).then(res => {
        const { data } = res.data
        this.$store.commit(`${this.MODULE_NAME}/SET`, data)
      }).catch(err => {
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false
      })
    },
  },

  setup() {
    const { MODULE_NAME, MODULE_NAME_CLONE } = config()

    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, transferModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
